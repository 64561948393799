:root {
  html,
  body {
    padding: 0;
    margin: 0;
    font-family: var(--base-font-family), -apple-system, BlinkMacSystemFont,
      Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
      Helvetica Neue, sans-serif;
    font-size: var(--base-font-size);
    color: var(--color-grey1);
  }

  a {
    color: inherit;
    text-decoration: none;
    &.link {
      color: var(--color-primary);
      font-weight: 500;
    }
  }

  * {
    box-sizing: border-box;
  }

  p {
    a {
      color: var(--color-cta);
    }
  }

  button {
    font-family: var(--base-font-family), -apple-system, BlinkMacSystemFont,
      Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
      Helvetica Neue, sans-serif;
  }


  /** Alignment ------------------------------------------------------------------ */

  .is-text-align-right {
    text-align: right;
  }

  .is-text-align-center {
    text-align: center;
  }

  .is-text-align-justified {
    text-align: justify;
  }

  .is-text-align-justified-mobile {
    text-align: left;
  }

  .is-self-align-right {
    align-self: right;
  }

  .is-self-align-center {
    align-self: center;
  }

  .is-self-justify-right {
    justify-self: right;
  }

  .is-self-justify-center {
    justify-self: center;
  }

  /** Spacing ------------------------------------------------------------------- */

  .separator {
    padding-top: var(--padding-regular);
    margin-bottom: var(--padding-regular);
    border: none;
  }

  .separator-thin {
    padding-top: var(--padding-small);
    margin-bottom: var(--padding-small);
    border: none;
  }

  .separator-extra-thin {
    padding-top: var(--padding-extra-small);
    margin-bottom: var(--padding-extra-small);
    border: none;
  }

  .separator-with-text {
    width: 100%;
    padding-top: var(--padding-regular);
    margin-bottom: var(--padding-regular);
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    gap: var(--padding-small);
    align-items: center;

    span {
      text-align: center;
      font-size: .9rem;
      color: var(--color-grey3);
    }

    .separator-with-text-line {
      border-top: 1px solid var(--color-grey3);
    }
  }

  /** Colors -------------------------------------------------------------------- */

  .color-primary {
    color: var(--color-primary);
  }

  .background-primary {
    background-color: var(--color-primary);
  }

  .background-light {
    background-color: var(--color-light);
  }

  .background-white {
    background-color: var(--color-white);
  }

  .background-grey-light {
    background-color: var(--color-grey-light);
  }

  /** Forms -------------------------------------------------------------------- */
  .form-group-title {
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 var(--padding-regular) 0;
    color: var(--color-text-secondary);
  }

  .form-label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
    color: var(--color-text-secondary);
  }

  input[type=text], input[type=email], input[type=number], input[type=password], input[type=password], select {
    font-family: var(--base-font-family), -apple-system, BlinkMacSystemFont,
      Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
      Helvetica Neue, sans-serif;
    font-size: var(--base-font-size);
  }

  /** Headings ----------------------------------------------------------------- */
  .title-with-action {
    border-bottom: 2px solid var(--color-grey5);
    margin: 0 0 var(--padding-regular) 0;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto min-content;
    align-items: center;
    h2 {
      font-size: 18px;
      font-weight: 500;
    }
  }
}



/** Layout -------------------------------------------------------------------- */

.main-container {
  min-height: var(--page-min-height);
}

.page-container {
  min-height: var(--page-min-height);
  .page-content-section {
    padding: var(--padding-regular) 0;
    .page-content-block {
      max-width: var(--content-block-max-width);
      margin: auto;
    }
    &.is-spaced {
      padding: var(--padding-large) 0;
    }
    &.is-narrow {
      .page-content-block {
        max-width: var(--content-block-narrow-max-width);
        margin: auto;
      }
    }
    &.is-narrower {
      .page-content-block {
        max-width: var(--content-block-narrower-max-width);
        margin: auto;
      }
    }
  }
}

.section-heading {
  display: flex;
  align-items: center;
  flex-direction: column;

  .section-heading-title {
    font-size: 32px;
    margin: 1.5rem 0 1rem 0;
  }

  .section-heading-subtitle {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    max-width: 750px;
    margin: 0 0 1rem 0;
    color: var(--color-grey2);
  }

  &.is-narrow {
    .section-heading-title {
      margin: .95rem 0;
    }

    .section-heading-subtitle {
      max-width: 500px;
      margin: .5rem 0;
    }
  }
}

.checkboxes-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sticky-toolbar {
  position: sticky;
  top: -1px;
  padding: var(--padding-small) 0;
  z-index: 9999;
}

.buttons-toolbar {
  Button {
    margin-left: var(--padding-regular);
  }
}

.two-columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--padding-large);
}

.text-block-with-large-icon {

  h3 {
    color: var(--color-grey2);
  }

  width: 40%;
  margin: var(--padding-large) auto 0 auto;
  text-align: center;

  svg {
    height: 96px;
  }

}

.loader-ellipsis {
  width: 60px;
  margin: var(--padding-regular) auto;
  aspect-ratio: 1;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, #ffa516 94%, #0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%, #ffa516);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: loader-ellipsis-animation 1s infinite linear;
}

.loader-ellipsis-inline {
  width: 22px;
  margin: 0;
  aspect-ratio: 1;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, #ffa516 94%, #0000) top/3px 3px no-repeat,
    conic-gradient(#0000 30%, #ffa516);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 3px), #000 0);
  animation: loader-ellipsis-animation 1s infinite linear;

  &.is-contrast {
    background:
      radial-gradient(farthest-side, #666 94%, #0000) top/3px 3px no-repeat,
      conic-gradient(#0000 30%, #666);
  }
}

@keyframes loader-ellipsis-animation {
  100% {
    transform: rotate(1turn)
  }
}

@media screen and (min-width: 1200px) {
  .page-container {
    min-height: var(--page-min-height);
    .page-content-section {
      padding: var(--padding-regular) 0;

      .page-content-block {
        max-width: var(--content-block-max-width);
        margin: auto;
      }

      &.is-spaced {
        padding: var(--padding-large) var(--padding-regular);
      }

      &.is-narrow {
        .page-content-block {
          max-width: var(--content-block-narrow-max-width);
          margin: auto;
        }
      }

      &.is-narrower {
        .page-content-block {
          max-width: var(--content-block-narrower-max-width);
          margin: auto;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .left-padded {
    padding-left: 3rem;
  }
}

@media screen and (max-width: 820px) {
  .page-container {
    .page-content-section {
      padding: var(--padding-regular);

      &.is-spaced {
        padding: calc(var(--padding-regular) * 1.5) var(--padding-regular);
      }

      &.is-narrow {
        .page-content-block {
          max-width: 100%;
          margin: auto;
        }
      }

      &.is-narrower {
        .page-content-block {
          max-width: 100%;
          margin: auto;
        }
      }
    }
  }

  .text-block-with-large-icon {
    width: 60%;
    margin: var(--padding-large) auto;
  }
}

@media screen and (max-width: 576px) {
  h1, h2, h3, h4 {
    overflow-wrap: break-word;
  }

  .section-heading {
    .section-heading-title {
      font-size: 30px;
      text-align: center;
    }
  }

  :root {
    .is-text-align-justified-mobile {
      text-align: justify;
    }
  }

  .two-columns {
    display: block
  }

  .text-block-with-large-icon {
    width: 90%;
    margin: var(--padding-regular) auto;
  }

  .mobile-align-center {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}